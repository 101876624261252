@font-face{
    font-family: DigifaceWide ;
    src:url('./assets/DigifaceWide.ttf') ;
}

.digitalNumber{
    font-family:DigifaceWide !important;
    width: 30px;
    height:30px;
    border:3px grey solid;
    border-radius:15px;
    justify-content:center;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.digitalNumber_inside{
    margin-top: 2px;
}

.singleLightContainer{
    width: 32px;
    height: 60px;
}

.showContainer{
    width: 32px;
    height: 60px;
    background-color: black;
}

.singleLightContainerRow{
    width: 60px;
    height: 32px;
}

.showContainerRow{
    width: 60px;
    height: 32px;
    background-color: black;
    display: flex;
}

.columnLightMid{
    display: flex;
    justify-content: center;
    align-items: center;
}

.circleLight{
    width:20px;
    height:20px;
    border:1px solid rgba(255,255,255,0);
    border-radius: 10px;
    margin-top: 2px;
}