/*.videoWrapCon{
    max-width:480px;
    max-height:337px;
!*    min-width:150px;
    min-height:89px;*!
    background:rgba(255,255,255,1);
    position:absolute;
    z-index:100;
    padding:10px 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}*/
.closeVideoIcon{
    float:right;
    margin-top:-5px;
    margin-bottom:5px;
    cursor:pointer;
}
.videoCon{
    /*max-width:510px;
    max-height:300px;*/
    width:100%;
    height:97%;
    position:relative;
    clear:both;

}
.spinCon{
    width:100%;
    height:97%;
}

.spinCon {
    width:100%;
    height:97%;
}

.videoCon video{
    /*max-width:490px;
    max-height:300px;*/
    width:100%;
    height:97%;
    background:black;
}
.videoCon .failTip{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white;
}
.videoControlBar{
    height:36px;
    background:rgba(0,0,0,0.5);
    color:#1890ff;
    position:absolute;
    bottom:3%;
    width:100%;


}
.videoBarIcon{
    margin-top:10px;
    margin-left:2px;
    font-size:18px;
    float:left;
    cursor: pointer;
}
.snapshot{
    margin-top:10px;
    margin-right:5px;
    font-size:18px;
    float:right;
    cursor: pointer;
}