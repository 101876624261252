.arrow{
    display: flex;
    width: 17px;
    height: 17px;
    border-width: 5px 5px 0px 0px;
    border-color: #CCCCCC;
    border-style: solid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .arrowright{
    transform: rotate(45deg);
  }
  
  .arrowtop{
    transform: rotate(-45deg);
  }
  
  .arrowleft{
    transform: rotate(-135deg);
  }
  
  .arrowdown{
    transform: rotate(135deg)
  }
  
  .barrow{
    width: 12px;
    height: 5px;
    background-color: #CCCCCC;
    transform: rotate(-45deg);
  }
  
  .barcol{
    width: 5px;
    height: 12px;
    background-color: #CCCCCC;
    transform: rotate(45deg);
  }

  .arrcontainer{
    margin:10px;
  }

  .cubetext{
      margin-right: 3rem;
      max-width: 72px;
  }

  .roadcube{
      width: 80px;
      height: 80px;
      /* background-color:#3399ff; */
      background-color:#CCCCCC;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      cursor: pointer;
  }

  .status_and_timing_title{
    font-size: 16px;
    width: 7em;
    color: rgb(51, 153, 255);
  }

  .status_and_timing_line_container{
    display: inline-flex;
  }
