body{
    // 全局样式
    :global{
        .ant-table-body{
            background:white
        }
        // .ant-table-thead > tr > th{
        //     background: white;
        //     // font-size: 16px;
        //     text-align: center;
        // }
        // .ant-table-tbody > tr > td {
        //     text-align: center;
        // }
        .ant-layout-sider{
            background: rgb(46, 50, 62)
        }
        // .loginCheck{
        //     .ant-checkbox-checked .ant-checkbox-inner{
        //         background:#0b3885;
        //         border-color: #0b3885
        //     }
        // }
    }
}
// table里面每个column的字体颜色
.column_font{
    color:  #8A96A0;;
    // background:"white"
}

