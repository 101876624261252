
.centerStyle{
    position: absolute !important;
    top: 40% !important;
    left: 40% !important;
    transform:translate(-50%,-50%) !important;
}
.title{
    padding:10px 0px;
    font-size:26px;
    text-align: center;
    color:white;
    background: -webkit-linear-gradient(left, #1c1d47 ,#313468, #313468,#1d204b); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #1c1d47 ,#313468, #313468,#1d204b); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right,  #1c1d47 ,#313468, #313468,#1d204b); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right,  #1c1d47 ,#313468, #313468,#1d204b); /* 标准的语法（必须放在最后） */
}
.grad {
    color:white !important;
    background: -webkit-linear-gradient(left, #1c1d47 ,#313468, #313468,#1d204b); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #1c1d47 ,#313468, #313468,#1d204b); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right,  #1c1d47 ,#313468, #313468,#1d204b); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right,  #1c1d47 ,#313468, #313468,#1d204b); /* 标准的语法（必须放在最后） */
    font-family: '微软雅黑';

}
.totalNum{
    color:white;
    padding:10px;
    margin-bottom:20px;
    background: -webkit-linear-gradient(left, #1c2757 ,#2e3a72, #2e3a72,#1a2353); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #1c2757 ,#2e3a72, #2e3a72,#1a2353); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #1c2757 ,#2e3a72, #2e3a72,#1a2353); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #1c2757 ,#2e3a72, #2e3a72,#1a2353); /* 标准的语法（必须放在最后） */

}
.itemCon{
    margin-top:14px;
    margin-bottom:14px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #EBEDF8;
}
.itemCon:last-child{
    border:0px;
}
.itemIcon{
    width:50px;
    height:50px;
    float:left;
    margin-left:45px;
}
.itemTxt{
    float:left;
    font-size:14px;
    color:white;
    margin-top:10px;
    margin-left:10px;
    padding-bottom:10px;
    border-bottom: 2px solid #EBEDF8;

}
.itemNum{
    float:left;
    font-size: 32px;
    color:white;
    margin-left:25px;
}
.statisticsCon{
    position:relative;
    height:calc(100vh - 230px);
    /*min-height:800px;*/
    overflow:hidden;
    background: -webkit-linear-gradient(left, #1c2757 ,#2e3a72,#1a2353); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #1c2757 ,#2e3a72, #1a2353); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #1c2757 ,#2e3a72,#1a2353); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #1c2757 ,#2e3a72, #1a2353); /* 标准的语法（必须放在最后） */
}
.emptyCon{
    height: calc(100vh - 230px);width:100%;zIndex:1;
    text-align: center;
    background: -webkit-linear-gradient(left, #1c2757 ,#2e3a72,#1a2353); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #1c2757 ,#2e3a72, #1a2353); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #1c2757 ,#2e3a72,#1a2353); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #1c2757 ,#2e3a72, #1a2353); /* 标准的语法（必须放在最后） */
}
.emptyTips{
    padding-top:20%;
    font-size:16px;
}
.testFieldListTitle{
    position:absolute;
    bottom:170px;
    width:98%;
}
.testFieldListTitle dd{
    float:left;
    height:40px;
    line-height: 40px;
    text-align:center; font-size:12px;color: #9BE0FF;
}
.testFieldListTitle dd:nth-child(1){ width:27%;overflow: hidden}
.testFieldListTitle dd:nth-child(2){ margin-left:1%;width:27%;overflow: hidden}
.testFieldListTitle dd:nth-child(3){ width:25%;overflow: hidden}
.testFieldListTitle dd:nth-child(4){ width:20%;overflow: hidden}

.downIcon{
  height:30px;
  /* float:right; */
  margin-left:10px;
}
.upIcon{
  height:30px;
  /* float:right; */
  margin-bottom: 35px;
  margin-left:10px;
}
.testFieldListMaquee{
    height:210px;
    width:98%;
    overflow: hidden;
    position:absolute;
    bottom:0px;
    z-index: 5;

}
.testFieldListMaqueeDown{
    /* height:210px; */
    width:98%;
    overflow: hidden;
    position:absolute;
    bottom:0px;
    z-index: 5;

}
.deviceListMaquee{
    height:170px;
    width:100%;
    overflow: hidden;
    position:relative;

}
.testFieldList {
    height:170px;
    margin-top:0px;


}

.animation{
    margin-top:-60px;
    transition: All 0.4s ease-in;
    -webkit-transition: All 0.4s ease-in;
    -moz-transition: All 0.4s ease-in;
    -o-transition: All 0.4s ease-in;
}

.testFieldList li{
    width:100%; height:50px;line-height: 50px;  text-align:center; font-size:14px; background:rgba(29,51,107,0.5);border-left:8px solid #0089e8;
    margin-bottom:10px;
    cursor: pointer;
    color:#9BE0FF;


}
.testFieldList li:hover{
    background: -webkit-linear-gradient(left, #086cbc ,#114b8f,#1e235a); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #086cbc ,#114b8f,#1e235a); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #086cbc ,#114b8f,#1e235a); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #086cbc ,#114b8f,#1e235a); /* 标准的语法（必须放在最后） */

}
.testFieldList li div{
    float:left;
    height:50px;
}
.testFieldList li div:nth-child(1){ margin:0 3px;width:20%;overflow: hidden ;text-overflow:ellipsis;
    white-space: nowrap;}
.testFieldList li div:nth-child(2){ margin-left:1%;width:28%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
.testFieldList li div:nth-child(3){ width:25%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
.testFieldList li div:nth-child(4){ width:20%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
@keyframes moveMarginTop
{
    from {margin-top:0px;}
    to {margin-top:-39px;}
}

@-webkit-keyframes moveMarginTop /*Safari and Chrome*/
{
    from {margin-top:0px;}
    to {margin-top:-39px;}
}
.statisticsTitle{
    padding:10px 27px;
    margin-top:10px;
    margin-left:50px;
    margin-bottom:5px;
    float:left;
    background: -webkit-linear-gradient(left, #47C8F7 ,#FF4B76); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #47C8F7 ,#FF4B76); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #47C8F7 ,#FF4B76); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #47C8F7 ,#FF4B76); /* 标准的语法（必须放在最后） */
}
.deviceListTitle{
    clear:both;
}
.deviceListTitle dd{
    float:left;
    height:40px;
    line-height: 40px;
      text-align:center; font-size:12px;color: #9BE0FF;
}
.deviceListTitle dd:nth-child(1){ width:20%;overflow: hidden}
.deviceListTitle dd:nth-child(2){ margin-left:1%;width:38%;overflow: hidden}
.deviceListTitle dd:nth-child(3){ width:25%;overflow: hidden}
.deviceListTitle dd:nth-child(4){ width:10%;overflow: hidden}

.deviceList li{
    width:100%; height:50px;line-height: 50px;  text-align:center; font-size:14px; background:rgba(29,51,107,0.5);border-left:8px solid #0089e8;
    margin-bottom:10px;
    cursor: pointer;
    color:#9BE0FF;


}
.deviceList li:hover{
    background: -webkit-linear-gradient(left, #086cbc ,#114b8f,#1e235a); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient(right, #086cbc ,#114b8f,#1e235a); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient(right, #086cbc ,#114b8f,#1e235a); /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #086cbc ,#114b8f,#1e235a); /* 标准的语法（必须放在最后） */

}
.deviceList li div{
    float:left;
    height:50px;
}
.deviceList li div:nth-child(1){ margin:0 3px;width:20%;overflow: hidden ;text-overflow:ellipsis;
    white-space: nowrap;}
.deviceList li div:nth-child(2){ margin-left:1%;width:38%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
.deviceList li div:nth-child(3){ width:25%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
.deviceList li div:nth-child(4){ width:10%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}

.statisticChart{
    width:100%;

    /*height:calc(100vh - 230px - 50vh);*/
}
.deviceChart{
  width:100%;
  margin-top:9vh;
}

.statisticChartTitle{
    display:flex;
    align-items: center;
}
.top{
    top:-60px;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
}
.center{
    top:0;
    z-index: 1;
    transition: left 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
}
.bottom{
    top:60px;
    z-index:-1;
}

.rowstyle{
  height:calc(100vh - 230px)
}

.rowstylehalf{
  height:300px
}

.rowstylehalf2{
  height:calc(50vh - 165px)
}



#allmap img{
    max-width: none;
}
.allmap > div >div {
    background-color: rgb(29, 44, 101) !important;

}
