.uploadCon{
    padding:20px;
    border:1px dotted #ececec;
    text-align: center;
    width:150px;
    height:100px;
    margin:0 auto;
}
.ant-upload-text {
    margin-top: 8px;
    color: #666;
}
.uploadFile{
    height:22px;
    margin-top:10px;
}
.uploadFile:hover{
    background-color:#e6f7ff;
    cursor: pointer;
}