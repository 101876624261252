.main{
    width: 100%;
}

.header{
    width:100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
}

.input{
    width:120px;
}

.filter{
    width: auto;
}

.switchView{
    width:auto;
}

.switchIcon{
    margin:0 10px;
    font-size: 28px;
}

.switchIcon:hover{
    cursor: pointer;
    transform: scale(1.2);
}

.formBasicInfoCon{
    display: flex;
    margin:0 0 30px 0;
}

.formMain{
    width: 100%;
    height: 100%;
    padding: 20px 10px;
}

.formBasicInfo{
    text-align: center;
}

.formTitle{
    font-weight: bold;
}

.formUrl{
    padding-left: 50px;
    margin:0 0 30px 0;
}

.formBasicInfoStart{
    padding-left: 50px;
}

.Checkbox{
    padding-left: 50px;
    margin:0 0 30px 0;
}

.latLngInputCon{
    display: flex;
    padding-left: 50px;
    position: relative;
    margin:0 0 30px 0;
}

.latLng{
    flex-basis: 50%;
}

.editIcon{
    position: absolute;
    right:180px;
    font-size: 18px;
}

.editIcon:hover{
    cursor: pointer;
    transform: scale(1.3)
}

.resetIcon{
    position: absolute;
    right:140px;
    font-size: 18px;
}

.resetIcon:hover{
    cursor: pointer;
    transform: scale(1.3);
}

.latLngInput{
    width: 120px;
}

.remark{
    padding-left: 50px;
    margin:0 0 30px 0;
    position: relative;
}

.mapCon{
    padding-left: 50px;
    position: relative;
    width: 100%;
    height: 300px;
    margin:0 0 50px 0;
}

.confirmCon{
    padding-left: 50px;
    text-align: center;
}

.remarksEditIcon{
    position: absolute;
    right:80px;
    font-size: 18px;
    top:0px;
}

.remarksEditIcon:hover{
    cursor: pointer;
    transform: scale(1.3)
}

.gridWrapper{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(300px, auto);
    margin:0 auto;
    /* max-width: 1200px; */
}

.imgGrid{
    position: relative;
    background-color:#D3D3D3; 
}

.snapShot{
    width:90%;
    height:85%;
    position:absolute;
    top:10px;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
}

.info{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
}

.camAddr{
    margin: 10px 0;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.gridEditIcon{
    position: absolute;
    bottom: 10px;
    right: 16px;
    font-size: 18px;
}

.gridEditIcon:hover{
    cursor: pointer;
    transform: scale(1.3);
}

.playIcon{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    color: aliceblue;
    display: block;
    width: 80px;
    height: 80px;
    font-size: 40px;
    transition:transform 0.4s;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.playIcon:hover{
    cursor: pointer;
    transform: scale(1.3);
}

.camTableRow{
    white-space: nowrap;
}

.empty{
    width:95%;
    height:85%;
    position:absolute;
    top:10px;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.2)
}