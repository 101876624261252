.statistics{

}
.totalNum1{
    padding:19px;
    background-color:#FFFFFF;

}
.itemCon1{
   margin-top:34px;
    margin-bottom:34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #EBEDF8;
}
.itemCon1:last-child{
    border:0px;
}
.itemIcon1{
    width:50px;
    height:50px;
    float:left;
}
.itemTxt1{
    float:left;
    font-size:14px;
    color:#666;
    margin-top:10px;
    margin-left:10px;
    padding-bottom:10px;
    border-bottom: 2px solid #EBEDF8;

}
.itemNum1{
    float:left;
    font-size: 32px;
    color: #333333;
    margin-left:15px;
}
.statisticViewCon{
    margin-top:30px;
}
.mapCon{
   /* background-color:white;*/
}
.mapTitle{
    padding-top:10px;
    padding-left:10px;
    padding-bottom:10px;
    font-size: 18px;
    color: #1A173B;
    font-weight: bold;
    background-color:white;
    clear:both;
}
.mapTitle .testFieldName{
    font-size: 16px;
    color: #1A173B;
    font-weight: normal;
}
.testFieldPosition{
    float:right;
    margin-right: 2%;
    cursor: pointer;
}
.chartCon1{
    background-color:white;
}
.centerStyle{
    position: absolute !important;
    top: 40% !important;
    left: 40% !important;
    transform:translate(-50%,-50%) !important;
}
.eventQuantity{
    width: 100% !important;height: 300px !important;
    position:relative !important;
}
.eventType{
    width: 100%!important; min-height: 400px!important;position:relative!important;
}
.noData{
    background: url("./img/zwsj.png");
    background-size: 100% 100%;
    width:100%;
    height: 350px;
}


















