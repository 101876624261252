.DirectRectCon{
    display:flex;
    flex-wrap:wrap;
    width:300px;
    height:300px;
    box-sizing:border-box;
    margin:-150px 0 0 -150px;
  }
  
  .DirectRectChild{
    box-sizing:border-box;
    width:150px;
    height:150px;
    border:1px solid blue;
    line-height:150px;
    text-align:center;
    color:red
  }

  .infobox{
    width:300px;
    height: 300px;
  }