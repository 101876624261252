.monitor_tab_choosen{
    width: 50%;
    background-color:#2CABE3;
    color:#FFFFFF;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    position:relative;
}

.monitor_tab_unchoosen{
    width: 50%;
    background-color:#F7FAFC;
    color:#333333;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    position:relative;
}

.monitor_tab_choosen:after{
    display:block;
    content:'';
    position: absolute;
    left:50%;
    top:100%;
    border-width:8px 8px 8px 8px;
    border-style:solid;
    border-color:#2CABE3 transparent transparent transparent;
    z-index: 1;
}

