.videoWrapCon{
    max-width:370px;
    max-height:260px;
    min-width:150px;
    min-height:89px;
    background:rgba(255,255,255,1);
    position:absolute;
    z-index:100;
    padding:10px 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.closeVideoIcon{
    float:right;
    margin-top:-5px;
    margin-bottom:5px;
    cursor:pointer;
}
.videoCon{
    max-width:350px;
    max-height:196px;
    min-width:150px;
    min-height:89px;
    position:relative;
    clear:both;

}
.videoCon video{
    max-width:350px;
    max-height:176px;
    min-width:150px;
    min-height:89px;
    background:black;
}
.videoCon .failTip{
    position:absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color:white;
}