.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-ul{
  display: flex;
  width: 300px;
}
.header-ul li {
  flex-grow: 1;
  text-align: center;
}
.header-ul>li img{
  width: 35px;
  height: 35px;
  border-radius: 100px;
}


.menu{
  line-height: 2em;
  padding: 0 10px;
}
.menu-group li{
  padding: 0 24px;
  line-height: 2em;
}