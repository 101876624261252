/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: "Roboto" */
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.header-ul {
  display: flex;
  width: 300px; }

.header-ul li {
  flex-grow: 1;
  text-align: center; }

.header-ul > li img {
  width: 35px;
  height: 35px;
  border-radius: 100px; }

.menu {
  line-height: 2em;
  padding: 0 10px; }

.menu-group li {
  padding: 0 24px;
  line-height: 2em; }

.siderNav_siderAffix__FpQT6{
    position:fixed;
}
.uploadCon{
    padding:20px;
    border:1px dotted #ececec;
    text-align: center;
    width:150px;
    height:100px;
    margin:0 auto;
}
.ant-upload-text {
    margin-top: 8px;
    color: #666;
}
.uploadFile{
    height:22px;
    margin-top:10px;
}
.uploadFile:hover{
    background-color:#e6f7ff;
    cursor: pointer;
}
body .ant-table-body {
  background: white; }

body .ant-layout-sider {
  background: #2e323e; }

.column_font {
  color: #8A96A0; }


.user_tt__2_6t2{
    width:50px;
    height:50px;
    background-color: #ffffff;
    color:red
}
.btn {
  background: red; }

@font-face{
    font-family: DigifaceWide ;
    src:url(/static/media/DigifaceWide.3e3f59bc.ttf);
}

.light_digitalNumber__2e9Oy{
    font-family:DigifaceWide !important;
    width: 30px;
    height:30px;
    border:3px grey solid;
    border-radius:15px;
    justify-content:center;
    font-size: 14px;
    display: flex;
    justify-content: center;
}

.light_digitalNumber_inside__2CAGK{
    margin-top: 2px;
}

.light_singleLightContainer__2_05I{
    width: 32px;
    height: 60px;
}

.light_showContainer__2FGnW{
    width: 32px;
    height: 60px;
    background-color: black;
}

.light_singleLightContainerRow__2EBOF{
    width: 60px;
    height: 32px;
}

.light_showContainerRow__1VKTi{
    width: 60px;
    height: 32px;
    background-color: black;
    display: flex;
}

.light_columnLightMid__30PNt{
    display: flex;
    justify-content: center;
    align-items: center;
}

.light_circleLight__1_TyI{
    width:20px;
    height:20px;
    border:1px solid rgba(255,255,255,0);
    border-radius: 10px;
    margin-top: 2px;
}
.viewAndMonitor_player_play__1No_V{
    font-size: 32px
}
.viewAndMonitor_videoWrapCon__1QQhh{
   /* max-width:370px;
    max-height:260px;*/
    width:510px;
    height:340px;
    /*min-width:150px;
    min-height:89px;*/
    background:rgba(255,255,255,1);
    position:absolute;
    z-index:100;
    padding:10px 10px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

/* .player_play:hover{
    transform: scale(1.2);
    transition:.3s
} */
/*.videoWrapCon{
    max-width:480px;
    max-height:337px;
!*    min-width:150px;
    min-height:89px;*!
    background:rgba(255,255,255,1);
    position:absolute;
    z-index:100;
    padding:10px 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}*/
.video_closeVideoIcon__352qz{
    float:right;
    margin-top:-5px;
    margin-bottom:5px;
    cursor:pointer;
}
.video_videoCon__1G5pr{
    /*max-width:510px;
    max-height:300px;*/
    width:100%;
    height:97%;
    position:relative;
    clear:both;

}
.video_spinCon__2h8a3{
    width:100%;
    height:97%;
}

.video_spinCon__2h8a3 {
    width:100%;
    height:97%;
}

.video_videoCon__1G5pr video{
    /*max-width:490px;
    max-height:300px;*/
    width:100%;
    height:97%;
    background:black;
}
.video_videoCon__1G5pr .video_failTip__1zg8o{
    position:absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color:white;
}
.video_videoControlBar__3TlRT{
    height:36px;
    background:rgba(0,0,0,0.5);
    color:#1890ff;
    position:absolute;
    bottom:3%;
    width:100%;


}
.video_videoBarIcon__BKBb3{
    margin-top:10px;
    margin-left:2px;
    font-size:18px;
    float:left;
    cursor: pointer;
}
.video_snapshot__zlo53{
    margin-top:10px;
    margin-right:5px;
    font-size:18px;
    float:right;
    cursor: pointer;
}

.list_toggleRSEId__18yCM{
        float: right;
    }
.list_tableRowClass__3wypr{
    cursor: pointer;
}    

.arrow{
    display: flex;
    width: 17px;
    height: 17px;
    border-width: 5px 5px 0px 0px;
    border-color: #CCCCCC;
    border-style: solid;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .arrowright{
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
  
  .arrowtop{
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  .arrowleft{
    -webkit-transform: rotate(-135deg);
            transform: rotate(-135deg);
  }
  
  .arrowdown{
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg)
  }
  
  .barrow{
    width: 12px;
    height: 5px;
    background-color: #CCCCCC;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  .barcol{
    width: 5px;
    height: 12px;
    background-color: #CCCCCC;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }

  .arrcontainer{
    margin:10px;
  }

  .cubetext{
      margin-right: 3rem;
      max-width: 72px;
  }

  .roadcube{
      width: 80px;
      height: 80px;
      /* background-color:#3399ff; */
      background-color:#CCCCCC;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      cursor: pointer;
  }

  .status_and_timing_title{
    font-size: 16px;
    width: 7em;
    color: rgb(51, 153, 255);
  }

  .status_and_timing_line_container{
    display: inline-flex;
  }

.editAndView_DirectRectCon__23vRm{
    display:flex;
    flex-wrap:wrap;
    width:300px;
    height:300px;
    box-sizing:border-box;
    margin:-150px 0 0 -150px;
  }
  
  .editAndView_DirectRectChild__280q_{
    box-sizing:border-box;
    width:150px;
    height:150px;
    border:1px solid blue;
    line-height:150px;
    text-align:center;
    color:red
  }

  .editAndView_infobox__1OJwz{
    width:300px;
    height: 300px;
  }
.testFieldMonitor_monitor_tab_choosen__9PaJx{
    width: 50%;
    background-color:#2CABE3;
    color:#FFFFFF;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    position:relative;
}

.testFieldMonitor_monitor_tab_unchoosen__13otZ{
    width: 50%;
    background-color:#F7FAFC;
    color:#333333;
    line-height: 60px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    position:relative;
}

.testFieldMonitor_monitor_tab_choosen__9PaJx:after{
    display:block;
    content:'';
    position: absolute;
    left:50%;
    top:100%;
    border-width:8px 8px 8px 8px;
    border-style:solid;
    border-color:#2CABE3 transparent transparent transparent;
    z-index: 1;
}


.demo {
  margin: 16px; }

.demo-nav {
  height: 30px;
  line-height: 30px;
  margin-bottom: 16px;
  background: #f8f8f8; }

.demo-nav a {
  line-height: 30px;
  padding: 0 8px; }

.app-list {
  margin-top: 16px; }

.statistics{

}
.totalNum1{
    padding:19px;
    background-color:#FFFFFF;

}
.itemCon1{
   margin-top:34px;
    margin-bottom:34px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #EBEDF8;
}
.itemCon1:last-child{
    border:0px;
}
.itemIcon1{
    width:50px;
    height:50px;
    float:left;
}
.itemTxt1{
    float:left;
    font-size:14px;
    color:#666;
    margin-top:10px;
    margin-left:10px;
    padding-bottom:10px;
    border-bottom: 2px solid #EBEDF8;

}
.itemNum1{
    float:left;
    font-size: 32px;
    color: #333333;
    margin-left:15px;
}
.statisticViewCon{
    margin-top:30px;
}
.mapCon{
   /* background-color:white;*/
}
.mapTitle{
    padding-top:10px;
    padding-left:10px;
    padding-bottom:10px;
    font-size: 18px;
    color: #1A173B;
    font-weight: bold;
    background-color:white;
    clear:both;
}
.mapTitle .testFieldName{
    font-size: 16px;
    color: #1A173B;
    font-weight: normal;
}
.testFieldPosition{
    float:right;
    margin-right: 2%;
    cursor: pointer;
}
.chartCon1{
    background-color:white;
}
.centerStyle{
    position: absolute !important;
    top: 40% !important;
    left: 40% !important;
    -webkit-transform:translate(-50%,-50%) !important;
            transform:translate(-50%,-50%) !important;
}
.eventQuantity{
    width: 100% !important;height: 300px !important;
    position:relative !important;
}
.eventType{
    width: 100%!important; min-height: 400px!important;position:relative!important;
}
.noData{
    background: url(/static/media/zwsj.4ed06ad9.png);
    background-size: 100% 100%;
    width:100%;
    height: 350px;
}



















.home_videoWrapCon__DDfvU{
    max-width:370px;
    max-height:260px;
    min-width:150px;
    min-height:89px;
    background:rgba(255,255,255,1);
    position:absolute;
    z-index:100;
    padding:10px 10px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}
.home_closeVideoIcon__3C_J1{
    float:right;
    margin-top:-5px;
    margin-bottom:5px;
    cursor:pointer;
}
.home_videoCon__1h8SP{
    max-width:350px;
    max-height:196px;
    min-width:150px;
    min-height:89px;
    position:relative;
    clear:both;

}
.home_videoCon__1h8SP video{
    max-width:350px;
    max-height:176px;
    min-width:150px;
    min-height:89px;
    background:black;
}
.home_videoCon__1h8SP .home_failTip__2bzYI{
    position:absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    color:white;
}

.style_centerStyle__1pgty{
    position: absolute !important;
    top: 40% !important;
    left: 40% !important;
    -webkit-transform:translate(-50%,-50%) !important;
            transform:translate(-50%,-50%) !important;
}
.style_title__13bpl{
    padding:10px 0px;
    font-size:26px;
    text-align: center;
    color:white;
    background: -webkit-linear-gradient(left, #1c1d47 ,#313468, #313468,#1d204b); /* Safari 5.1 - 6.0 */ /* Opera 11.1 - 12.0 */ /* Firefox 3.6 - 15 */
    background: linear-gradient(to right,  #1c1d47 ,#313468, #313468,#1d204b); /* 标准的语法（必须放在最后） */
}
.style_grad__YqWYH {
    color:white !important;
    background: -webkit-linear-gradient(left, #1c1d47 ,#313468, #313468,#1d204b); /* Safari 5.1 - 6.0 */ /* Opera 11.1 - 12.0 */ /* Firefox 3.6 - 15 */
    background: linear-gradient(to right,  #1c1d47 ,#313468, #313468,#1d204b); /* 标准的语法（必须放在最后） */
    font-family: '\5FAE\8F6F\96C5\9ED1';

}
.style_totalNum__38cXk{
    color:white;
    padding:10px;
    margin-bottom:20px;
    background: -webkit-linear-gradient(left, #1c2757 ,#2e3a72, #2e3a72,#1a2353); /* Safari 5.1 - 6.0 */ /* Opera 11.1 - 12.0 */ /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #1c2757 ,#2e3a72, #2e3a72,#1a2353); /* 标准的语法（必须放在最后） */

}
.style_itemCon__3D3nr{
    margin-top:14px;
    margin-bottom:14px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #EBEDF8;
}
.style_itemCon__3D3nr:last-child{
    border:0px;
}
.style_itemIcon__1hNoJ{
    width:50px;
    height:50px;
    float:left;
    margin-left:45px;
}
.style_itemTxt__2fvk_{
    float:left;
    font-size:14px;
    color:white;
    margin-top:10px;
    margin-left:10px;
    padding-bottom:10px;
    border-bottom: 2px solid #EBEDF8;

}
.style_itemNum__ZpuMI{
    float:left;
    font-size: 32px;
    color:white;
    margin-left:25px;
}
.style_statisticsCon__1kM1F{
    position:relative;
    height:calc(100vh - 230px);
    /*min-height:800px;*/
    overflow:hidden;
    background: -webkit-linear-gradient(left, #1c2757 ,#2e3a72,#1a2353); /* Safari 5.1 - 6.0 */ /* Opera 11.1 - 12.0 */ /* Firefox 3.6 - 15 */
    background: -webkit-linear-gradient(left, #1c2757 ,#2e3a72, #1a2353);
    background: linear-gradient(to right, #1c2757 ,#2e3a72, #1a2353); /* 标准的语法（必须放在最后） */
}
.style_emptyCon__3TYgc{
    height: calc(100vh - 230px);width:100%;zIndex:1;
    text-align: center;
    background: -webkit-linear-gradient(left, #1c2757 ,#2e3a72,#1a2353); /* Safari 5.1 - 6.0 */ /* Opera 11.1 - 12.0 */ /* Firefox 3.6 - 15 */
    background: -webkit-linear-gradient(left, #1c2757 ,#2e3a72, #1a2353);
    background: linear-gradient(to right, #1c2757 ,#2e3a72, #1a2353); /* 标准的语法（必须放在最后） */
}
.style_emptyTips__2Cfv5{
    padding-top:20%;
    font-size:16px;
}
.style_testFieldListTitle__6M-zY{
    position:absolute;
    bottom:170px;
    width:98%;
}
.style_testFieldListTitle__6M-zY dd{
    float:left;
    height:40px;
    line-height: 40px;
    text-align:center; font-size:12px;color: #9BE0FF;
}
.style_testFieldListTitle__6M-zY dd:nth-child(1){ width:27%;overflow: hidden}
.style_testFieldListTitle__6M-zY dd:nth-child(2){ margin-left:1%;width:27%;overflow: hidden}
.style_testFieldListTitle__6M-zY dd:nth-child(3){ width:25%;overflow: hidden}
.style_testFieldListTitle__6M-zY dd:nth-child(4){ width:20%;overflow: hidden}

.style_downIcon__1woAI{
  height:30px;
  /* float:right; */
  margin-left:10px;
}
.style_upIcon__15Z9b{
  height:30px;
  /* float:right; */
  margin-bottom: 35px;
  margin-left:10px;
}
.style_testFieldListMaquee__3pctR{
    height:210px;
    width:98%;
    overflow: hidden;
    position:absolute;
    bottom:0px;
    z-index: 5;

}
.style_testFieldListMaqueeDown__371Ju{
    /* height:210px; */
    width:98%;
    overflow: hidden;
    position:absolute;
    bottom:0px;
    z-index: 5;

}
.style_deviceListMaquee__2Szg9{
    height:170px;
    width:100%;
    overflow: hidden;
    position:relative;

}
.style_testFieldList__2ZAwp {
    height:170px;
    margin-top:0px;


}

.style_animation__81RNL{
    margin-top:-60px;
    transition: All 0.4s ease-in;
    -webkit-transition: All 0.4s ease-in;
    -moz-transition: All 0.4s ease-in;
    -o-transition: All 0.4s ease-in;
}

.style_testFieldList__2ZAwp li{
    width:100%; height:50px;line-height: 50px;  text-align:center; font-size:14px; background:rgba(29,51,107,0.5);border-left:8px solid #0089e8;
    margin-bottom:10px;
    cursor: pointer;
    color:#9BE0FF;


}
.style_testFieldList__2ZAwp li:hover{
    background: -webkit-linear-gradient(left, #086cbc ,#114b8f,#1e235a); /* Safari 5.1 - 6.0 */ /* Opera 11.1 - 12.0 */ /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #086cbc ,#114b8f,#1e235a); /* 标准的语法（必须放在最后） */

}
.style_testFieldList__2ZAwp li div{
    float:left;
    height:50px;
}
.style_testFieldList__2ZAwp li div:nth-child(1){ margin:0 3px;width:20%;overflow: hidden ;text-overflow:ellipsis;
    white-space: nowrap;}
.style_testFieldList__2ZAwp li div:nth-child(2){ margin-left:1%;width:28%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
.style_testFieldList__2ZAwp li div:nth-child(3){ width:25%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
.style_testFieldList__2ZAwp li div:nth-child(4){ width:20%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
@keyframes style_moveMarginTop__2PeIH
{
    from {margin-top:0px;}
    to {margin-top:-39px;}
}

@-webkit-keyframes style_moveMarginTop__2PeIH /*Safari and Chrome*/
{
    from {margin-top:0px;}
    to {margin-top:-39px;}
}
.style_statisticsTitle__2jHXy{
    padding:10px 27px;
    margin-top:10px;
    margin-left:50px;
    margin-bottom:5px;
    float:left;
    background: -webkit-linear-gradient(left, #47C8F7 ,#FF4B76); /* Safari 5.1 - 6.0 */ /* Opera 11.1 - 12.0 */ /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #47C8F7 ,#FF4B76); /* 标准的语法（必须放在最后） */
}
.style_deviceListTitle__3tT1q{
    clear:both;
}
.style_deviceListTitle__3tT1q dd{
    float:left;
    height:40px;
    line-height: 40px;
      text-align:center; font-size:12px;color: #9BE0FF;
}
.style_deviceListTitle__3tT1q dd:nth-child(1){ width:20%;overflow: hidden}
.style_deviceListTitle__3tT1q dd:nth-child(2){ margin-left:1%;width:38%;overflow: hidden}
.style_deviceListTitle__3tT1q dd:nth-child(3){ width:25%;overflow: hidden}
.style_deviceListTitle__3tT1q dd:nth-child(4){ width:10%;overflow: hidden}

.style_deviceList__3Azql li{
    width:100%; height:50px;line-height: 50px;  text-align:center; font-size:14px; background:rgba(29,51,107,0.5);border-left:8px solid #0089e8;
    margin-bottom:10px;
    cursor: pointer;
    color:#9BE0FF;


}
.style_deviceList__3Azql li:hover{
    background: -webkit-linear-gradient(left, #086cbc ,#114b8f,#1e235a); /* Safari 5.1 - 6.0 */ /* Opera 11.1 - 12.0 */ /* Firefox 3.6 - 15 */
    background: linear-gradient(to right, #086cbc ,#114b8f,#1e235a); /* 标准的语法（必须放在最后） */

}
.style_deviceList__3Azql li div{
    float:left;
    height:50px;
}
.style_deviceList__3Azql li div:nth-child(1){ margin:0 3px;width:20%;overflow: hidden ;text-overflow:ellipsis;
    white-space: nowrap;}
.style_deviceList__3Azql li div:nth-child(2){ margin-left:1%;width:38%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
.style_deviceList__3Azql li div:nth-child(3){ width:25%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}
.style_deviceList__3Azql li div:nth-child(4){ width:10%;overflow: hidden;text-overflow:ellipsis;
    white-space: nowrap;}

.style_statisticChart__1ZpQe{
    width:100%;

    /*height:calc(100vh - 230px - 50vh);*/
}
.style_deviceChart__uelaV{
  width:100%;
  margin-top:9vh;
}

.style_statisticChartTitle__16Y-J{
    display:flex;
    align-items: center;
}
.style_top__OULvj{
    top:-60px;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
}
.style_center__16atx{
    top:0;
    z-index: 1;
    -webkit-transition: left 1s ease-in-out;
    transition: left 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
}
.style_bottom__30kaC{
    top:60px;
    z-index:-1;
}

.style_rowstyle__1xoVk{
  height:calc(100vh - 230px)
}

.style_rowstylehalf__34H2Q{
  height:300px
}

.style_rowstylehalf2__1RKFh{
  height:calc(50vh - 165px)
}



#style_allmap__1wp3C img{
    max-width: none;
}
.style_allmap__1wp3C > div >div {
    background-color: rgb(29, 44, 101) !important;

}

.styles_main__2Ta0c{
    width: 100%;
}

.styles_header__5dXSf{
    width:100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
}

.styles_input__RGP_v{
    width:120px;
}

.styles_filter__xIsrO{
    width: auto;
}

.styles_switchView__2pixT{
    width:auto;
}

.styles_switchIcon__286cF{
    margin:0 10px;
    font-size: 28px;
}

.styles_switchIcon__286cF:hover{
    cursor: pointer;
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.styles_formBasicInfoCon__2X1pC{
    display: flex;
    margin:0 0 30px 0;
}

.styles_formMain__3TFFk{
    width: 100%;
    height: 100%;
    padding: 20px 10px;
}

.styles_formBasicInfo__2iatF{
    text-align: center;
}

.styles_formTitle__2XG4p{
    font-weight: bold;
}

.styles_formUrl__3yjnn{
    padding-left: 50px;
    margin:0 0 30px 0;
}

.styles_formBasicInfoStart__1pPe5{
    padding-left: 50px;
}

.styles_Checkbox__7Wum9{
    padding-left: 50px;
    margin:0 0 30px 0;
}

.styles_latLngInputCon__2_LqM{
    display: flex;
    padding-left: 50px;
    position: relative;
    margin:0 0 30px 0;
}

.styles_latLng__37HFz{
    flex-basis: 50%;
}

.styles_editIcon__1sXzK{
    position: absolute;
    right:180px;
    font-size: 18px;
}

.styles_editIcon__1sXzK:hover{
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3)
}

.styles_resetIcon__1L6r2{
    position: absolute;
    right:140px;
    font-size: 18px;
}

.styles_resetIcon__1L6r2:hover{
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.styles_latLngInput__2QRbP{
    width: 120px;
}

.styles_remark__1WAgE{
    padding-left: 50px;
    margin:0 0 30px 0;
    position: relative;
}

.styles_mapCon__1Vusr{
    padding-left: 50px;
    position: relative;
    width: 100%;
    height: 300px;
    margin:0 0 50px 0;
}

.styles_confirmCon__2swBz{
    padding-left: 50px;
    text-align: center;
}

.styles_remarksEditIcon__2bsLk{
    position: absolute;
    right:80px;
    font-size: 18px;
    top:0px;
}

.styles_remarksEditIcon__2bsLk:hover{
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3)
}

.styles_gridWrapper__1Zb-A{
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 20px;
    grid-auto-rows: minmax(300px, auto);
    margin:0 auto;
    /* max-width: 1200px; */
}

.styles_imgGrid__2XD4l{
    position: relative;
    background-color:#D3D3D3; 
}

.styles_snapShot__1Y4qx{
    width:90%;
    height:85%;
    position:absolute;
    top:10px;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
}

.styles_info__KlAj5{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    width: 100%;
}

.styles_camAddr__1J-9k{
    margin: 10px 0;
    width: 80%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.styles_gridEditIcon___g7Ie{
    position: absolute;
    bottom: 10px;
    right: 16px;
    font-size: 18px;
}

.styles_gridEditIcon___g7Ie:hover{
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.styles_playIcon__3w2x9{
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 9;
    color: aliceblue;
    display: block;
    width: 80px;
    height: 80px;
    font-size: 40px;
    -webkit-transition:-webkit-transform 0.4s;
    transition:-webkit-transform 0.4s;
    transition:transform 0.4s;
    transition:transform 0.4s, -webkit-transform 0.4s;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.styles_playIcon__3w2x9:hover{
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
}

.styles_camTableRow__1HIon{
    white-space: nowrap;
}

.styles_empty__aBg_a{
    width:95%;
    height:85%;
    position:absolute;
    top:10px;
    bottom:0;
    left:0;
    right:0;
    margin:0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255,255,255,0.2)
}
.login-form .ant-input{
    border-radius: 0
}



