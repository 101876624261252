.player_play{
    font-size: 32px
}
.videoWrapCon{
   /* max-width:370px;
    max-height:260px;*/
    width:510px;
    height:340px;
    /*min-width:150px;
    min-height:89px;*/
    background:rgba(255,255,255,1);
    position:absolute;
    z-index:100;
    padding:10px 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .player_play:hover{
    transform: scale(1.2);
    transition:.3s
} */